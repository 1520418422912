/* shootings */

.shootings-vcontainer {
  align-items: center;
  text-align: center;
}

/* end shootings */
/* about */

.text-over {
  width: 100vw;
  pointer-events: none;
  color: black;
  z-index: 99;
  position: absolute;
  width: 100%;
  margin: 0px auto;
  padding: 120px 0px;
}

main {
  width: 100vw;
  pointer-events: none;
  color: white;
  z-index: 99;
  position: absolute;
  width: 100%;
  margin: 0px auto;
  padding: 120px 0px;
}

.scroll-elements-text {
  padding-bottom: 775px;
  z-index: 99;
  text-align: left;
}

.scroll-elements-text-start {
  padding-top: 800px;
  text-align: left;
}

.typewriter-about {
  font-size: 75px;
  text-align: center;
  color: #FABEAB;
  position: relative;
}

.typewriter-about-meet {
  font-size: 40px;
  text-align: center;
  color: #FABEAB;
  position: relative;
}

/* end about */
/* home */

.bold-image {
  height: 110vh;
  background-size: cover;
}

.typewriter {
  position: absolute;
  top: 15%;
  font-size: clamp(70px, 10vw, 15vh);
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  color: #fff;
}

.big-map-container {
  position: relative;
}

.big-map-image {
  object-fit: contain;
  width: 100%;
  background-size: cover;
  z-index: -10;
}

.map-cards-container::-webkit-scrollbar {
  display: none;
}

.map-cards-container{
  -ms-overflow-style: none;
  scrollbar-width: none
}

/* end home */
/* general */

body {
  margin: 0;
  font-family: Lato Regular;
}

.undraggable-image {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.map-dot {
  z-index: 100;
  position: absolute;
  align-content: center;
  justify-content: center;
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.padt-app {
  margin-top: 100px;
}

.padb-app {
  margin-bottom: 100px;
}

.pady-app {
  margin-bottom: 100px;
  margin-top: 100px;
}

.padt-app-big {
  margin-top: 200px;
}

.padb-app-big {
  margin-bottom: 200px;
}

.center-this {
  padding-left: auto;
  padding-right: auto;
}

.big-heading {
  font-family: Lato Regular;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #FABEAB;
}

.sub-heading {
  font-family: Lato Regular;
  font-size: 30px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #FABEAB;
}

input[type=range]::-webkit-slider-thumb {
  background:rgb(250, 190, 171);
}

input[type=range]::-moz-range-thumb {
  background:rgb(250, 190, 171);
}

input[type=range]::-ms-thumb {
  background:rgb(250, 190, 171);
}


/*end general*/
/* font imports & react*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Lato Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato Light';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato Darkest';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh50XSwiPHA.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}